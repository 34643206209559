export default [
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/projects/Index.vue'),
    meta: {
      title: 'Projects - Bartum Energy Admin',
      authRequired: true,
    },
  },
  {
    path: '/projects/view-project',
    name: 'view-project',
    component: () => import('@/views/projects/ViewProject.vue'),
    meta: {
      title: 'View Project - Bartum Energy Admin',
      navActiveLink: 'projects',
      authRequired: true,
    },
  },
  {
    path: '/projects/add-project',
    name: 'add-project',
    component: () => import('@/views/projects/AddProject.vue'),
    meta: {
      title: 'Add Project - Bartum Energy Admin',
      navActiveLink: 'projects',
      authRequired: true,
    },
  },
  {
    path: '/projects/edit-project',
    name: 'edit-project',
    component: () => import('@/views/projects/EditProject.vue'),
    meta: {
      title: 'Edit Project - Bartum Energy Admin',
      navActiveLink: 'projects',
      authRequired: true,
    },
  },
  {
    path: '/projects/edit-geolocation/:id',
    name: 'edit-geolocation',
    component: () => import('@/views/projects/GetCoordinates.vue'),
    meta: {
      title: 'Edit geolocation - Bartum Energy Admin',
      navActiveLink: 'projects',
      authRequired: true,
    },
  },
]
