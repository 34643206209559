export default [
  {
    path: '/payment/:id',
    name: 'payment',
    component: () => import('@/views/payment/Index.vue'),
    meta: {
      title: 'Payment - Bartum Energy Admin',
      layout: 'full',
    },
  },
  {
    path: '/payment-plan/:id',
    name: 'payment',
    component: () => import('@/views/payment/PaymentPattern.vue'),
    meta: {
      title: 'Payment - Bartum Energy Admin',
      layout: 'full',
    },
  },

  {
    path: '/payment-success',
    name: 'paymentSuccess',
    component: () => import('@/views/payment/PaymentSuccess.vue'),
    meta: {
      title: 'Payment-Success - Bartum Energy Admin',
      layout: 'full',
    },
  },
]
