export default {
  getAddProjectForm(form) {
    const formData = new FormData()
    formData.append('projectname', form.projectName)
    formData.append('projecttype', form.projectType)
    formData.append('solarsystemsize', form.systemSize)
    formData.append('numberofpanels', form.solarPanelQuantity)
    formData.append('numberofbatteries', form.batteryQuantity)
    formData.append('description', form.description)
    formData.append('installationtype', form.installationType)
    formData.append('status', form.status)
    formData.append('clientid', form.client)
    formData.append('lgaid', form.lga)
    formData.append('productid', form.product)
    formData.append('price', form.price)
    formData.append('numberofinverters', form.inverterQuantity)
    formData.append('batterytypeid', form.batteryType)
    formData.append('invertertypeid', form.inverterType)
    formData.append('solarpaneltypeid', form.solarPanelType)
    formData.append('address', form.address)
    formData.append('discount', form.discount)
    formData.append('miscellaneous', form.miscellaneous)
    
    return formData
  },
  getSendMessageForm(form) {
    const formData = new FormData()
    formData.append('projectid', form.projectID)
    formData.append('message', form.message)
    formData.append('subject', form.subject)
    return formData
  },
  getEditProjectForm(form) {
    const formData = new FormData()
    formData.append('id', form.projectID)
    formData.append('projectname', form.projectName)
    formData.append('projecttype', form.projectType)
    formData.append('solarsystemsize', form.systemSize)
    formData.append('numberofpanels', form.solarPanelQuantity)
    formData.append('numberofbatteries', form.batteryQuantity)
    formData.append('description', form.description)
    formData.append('installationtype', form.installationType)
    formData.append('status', form.status)
    formData.append('clientid', form.client)
    formData.append('lgaid', form.lga)
    formData.append('productid', form.product)
    formData.append('price', form.price)
    formData.append('numberofinverters', form.inverterQuantity)
    formData.append('batterytypeid', form.batteryType)
    formData.append('invertertypeid', form.inverterType)
    formData.append('solarpaneltypeid', form.solarPanelType)
    return formData
  },
  getDeleteProjectForm(form) {
    const formData = new FormData()
    formData.append('id', form.projectID)
    return formData
  },
}
